<script setup lang="ts">
import RoomieTag from '@lahaus-roomie/roomie/src/components/RoomieTag/index.vue'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import RoomieDivider from '@lahaus-roomie/roomie/src/components/RoomieDivider/index.vue'
import BedroomIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/room.svg'
import VectorDownIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/vectors/down.svg'
import type { LocaleCode } from '@/config/locale-domains'

const { t, locale } = useI18n()
const localeCode = locale.value as LocaleCode
</script>

<script lang="ts">
export default {
  name: 'PurchaseStepsIntro'
}
</script>

<template>
  <div class="purchase-steps-intro">
    <h3 class="flex flex-col text-pine-600 display-6 md:display-3 mb-64 md:mb-88">
      <span>
        {{ t('title.1') }}
      </span>

      <span>
        {{ t('title.2') }}
      </span>
    </h3>

    <div class="purchase-steps-intro__card pb-64">
      <div class="purchase-steps-intro__vector">
        <RoomieIcon custom-styles="block w-56 h-48 md:w-88 md:h-74 text-spring-600">
          <VectorDownIcon />
        </RoomieIcon>
      </div>

      <div class="purchase-steps-intro__column1">
        <h3 class="display-5 md:display-1 mb-24 md:mb-54">
          {{ t('steps.1.number') }}
        </h3>

        <h6 class="hl-2-sb md:display-6 mb-12 md:mb-24">
          {{ t('steps.1.title') }}
        </h6>

        <p class="body-1-m md:hl-3-m">
          {{ t('steps.1.description') }}
        </p>
      </div>

      <div class="purchase-steps-intro__column2">
        <div class="relative md:pl-[10.1rem] md:pr-[5.2rem]">
          <div class="absolute top-[-2.4rem] md:!top-16 right-[2.4rem] md:right-[1rem]">
            <RoomieTag
              id="step1-bedroom"
              class="purchase-steps-intro__tag"
              variant="information"
              size="lg"
              :label="t('steps.1.bedroom_number')">
              <template #icon>
                <RoomieIcon custom-styles="w-24 h-24 md:w-48 md:h-48 text-pine-600">
                  <BedroomIcon />
                </RoomieIcon>
              </template>
            </RoomieTag>
          </div>

          <img
            :src="t('steps.1.background_image')"
            alt="Paso 1">
        </div>

        <div class="bottom-[-3rem] left-8 md:left-0 md:bottom-[4.4rem] absolute">
          <RoomieTag
            id="step1-price"
            size="lg"
            class="purchase-steps-intro__tag--price"
            variant="clear"
            :label="t('steps.1.price')" />
        </div>
      </div>
    </div>

    <div class="purchase-steps-intro__card pb-34 md:mt-52">
      <div class="purchase-steps-intro__vector md:!left-[64.8%]">
        <RoomieIcon custom-styles="block w-56 h-48 md:w-88 md:h-74 text-spring-600">
          <VectorDownIcon />
        </RoomieIcon>
      </div>

      <div class="purchase-steps-intro__column1 md:order-2">
        <h3 class="display-5 md:display-1 mb-24 md:mb-54">
          {{ t('steps.2.number') }}
        </h3>

        <h6 class="hl-2-sb md:display-6 mb-12 md:mb-24">
          {{ t('steps.2.title') }}
        </h6>

        <p class="body-1-m md:hl-3-m">
          {{ t('steps.2.description') }}
        </p>
      </div>

      <div class="purchase-steps-intro__column2 md:order-1">
        <div class="w-full md:w-fit text-pine-600 relative bg-white rounded-[2rem] p-32 md:px-50 md:py-80 min-w-[266px] md:min-w-[418px] min-h-[326px]">
          <p class="body-3-m md:hl-3-m mb-16 md:mb-24">
            {{ t('steps.2.payment_plan.separation.title') }}
          </p>

          <p class="mb-16 md:mb-24">
            <span class="hl-3-sb md:display-6">{{ t('steps.2.payment_plan.separation.price') }}</span>

            <span class="body-3-m md:hl-3-m">{{ t('steps.2.payment_plan.separation.currency') }}</span>
          </p>

          <div class="relative w-full h-2 bg-pine-200 rounded-lg mb-36 md:mb-50">
            <div
              class="absolute left-0 h-2 bg-spring-600 rounded-lg w-[28%]" />

            <span class="absolute top-1/2 left-[30%] w-5 h-5 bg-spring-600 rounded-full transform -translate-x-1/2 -translate-y-1/2 shadow-md" />
          </div>

          <div class="flex flex-col justify-between">
            <p class="flex justify-between">
              <span class="body-3-m md:hl-3-m">{{ t('steps.2.payment_plan.installments.title') }}</span>

              <span class="body-1-sb md:hl-2-sb">{{ t('steps.2.payment_plan.installments.price') }}</span>
            </p>

            <RoomieDivider
              class="my-12 md:my-20"
              color="pine-300"
              size="xs" />

            <p class="flex justify-between gap-x-8">
              <span class="flex flex-col body-3-m md:hl-3-m">
                {{ t('steps.2.payment_plan.last_payment.title') }}
                <span
                  v-if="localeCode === 'mx'"
                  class="body-3-m">{{ t('steps.2.payment_plan.last_payment.disclaimer') }}</span>
              </span>

              <span class="body-1-sb md:hl-2-sb">
                {{ t('steps.2.payment_plan.last_payment.price') }}
              </span>
            </p>

            <RoomieDivider
              class="my-12 md:my-20"
              color="pine-300"
              size="xs" />

            <p class="flex justify-between mb-16 md:mb-24">
              <span class="body-3-m md:hl-3-m">{{ t('steps.2.payment_plan.price.title') }}</span>

              <span class="body-1-sb md:hl-2-sb">{{ t('steps.2.payment_plan.price.value') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="purchase-steps-intro__card pb-64 md:mt-52">
      <div class="purchase-steps-intro__vector">
        <RoomieIcon custom-styles="block w-56 h-48 md:w-88 md:h-74 text-spring-600">
          <VectorDownIcon />
        </RoomieIcon>
      </div>

      <div class="purchase-steps-intro__column1">
        <h3 class="display-5 md:display-1 mb-24 md:mb-54">
          {{ t('steps.3.number') }}
        </h3>

        <h6 class="hl-2-sb md:display-6 mb-12 md:mb-24">
          {{ t('steps.3.title') }}
        </h6>

        <p class="body-1-m md:hl-3-m">
          {{ t('steps.3.description') }}
        </p>
      </div>

      <div class="purchase-steps-intro__column2">
        <div class="relative md:pl-[10.1rem] md:pr-[5.2rem]">
          <img
            src="https://assests-lahaus.imgix.net/static/images/purchase-steps/3.png?auto=compress,enhance,format"
            alt="Paso 3">

          <div class="purchase-steps-intro__timeline-container">
            <div class="timeline">
              <div class="timeline-item mb-[2rem] md:mb-[3.4rem]">
                <div class="timeline-dot top-0" />

                <div class="timeline-content relative top-[-1rem] right-[-.6rem]">
                  <span>{{ t('timeline.1.title') }}</span>

                  <span>{{ t('timeline.1.value') }}</span>
                </div>
              </div>

              <div class="timeline-item my-[2rem] md:my-[3.4rem]">
                <div class="timeline-dot" />

                <div class="timeline-content">
                  <span>{{ t('timeline.2.title') }}</span>

                  <span>{{ t('timeline.2.value') }}</span>
                </div>
              </div>

              <div class="timeline-item my-[2rem] md:my-[3.4rem]">
                <div class="timeline-dot md:bottom-0" />

                <div class="timeline-content bottom-[-.4rem]">
                  <span>{{ t('timeline.3.title') }}</span>

                  <span>{{ t('timeline.3.value') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="bottom-[-3rem] left-8 md:left-0 md:bottom-[4.4rem] absolute">
            <ClientOnly>
              <RoomieTag
                id="step3-bedrooms"
                class="purchase-steps-intro__tag"
                variant="information"
                size="lg">
                <template #content>
                  <div class="flex flex-col items-center">
                    <p class="text-pine-600 display-6 md:display-4">
                      {{ t('steps.3.builder.value') }}
                    </p>

                    <span class="body-2-sb md:hl-3-m">{{ t('steps.3.builder.title') }}</span>
                  </div>
                </template>
              </RoomieTag>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="./_index.scss"></style>
<i18n src="./i18n.json"></i18n>
