<script setup lang="ts">
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
const { t } = useI18n()

const emit = defineEmits(['investment-action'])
</script>

<script lang="ts">
export default {
  name: 'RealEstateHero'
}
</script>

<template>
  <div class="real-estate-hero">
    <h3 class="flex flex-col display-5 md:display-1 mb-24">
      <span>
        {{ t('title.1') }}
      </span>

      <span>
        {{ t('title.2') }}
      </span>
    </h3>

    <p class="real-estate-hero__card--description">
      {{ t('description.1') }}
    </p>

    <p class="real-estate-hero__card--description">
      {{ t('description.2') }}
    </p>

    <div class="real-estate-hero__cards-container">
      <div class="real-estate-hero__card text-center md:!py-80">
        <div class="w-full flex justify-center mb-28">
          <img
            src="https://assests-lahaus.imgix.net/static/images/real-estate-hero/planter.png?w=355&h=241&fit=crop"
            alt="Imagen de una matera"
            class="w-[164px] h-[111px] md:w-[355px] md:h-[241px] object-cover">
        </div>

        <h3 class="flex flex-col real-estate-hero__card--title mb-16 md:mb-28">
          <span>
            {{ t('cards.1.title.1') }}
          </span>

          <span>
            {{ t('cards.1.title.2') }}
          </span>
        </h3>

        <p class="real-estate-hero__card--description">
          {{ t('cards.1.description') }}
        </p>
      </div>

      <div class="real-estate-hero__card text-center md:!py-80">
        <div class="w-full flex justify-center mb-28">
          <img
            src="https://assests-lahaus.imgix.net/static/images/real-estate-hero/palms.png?w=355&h=241&fit=crop"
            alt="Imagen 2"
            class="w-[164px] h-[111px] md:w-[355px] md:h-[241px] object-cover">
        </div>

        <h3 class="flex flex-col real-estate-hero__card--title text-center mb-16 md:mb-28">
          <span>
            {{ t('cards.2.title.1') }}
          </span>

          <span>
            {{ t('cards.2.title.2') }}
          </span>
        </h3>

        <p class="real-estate-hero__card--description">
          {{ t('cards.2.description') }}
        </p>
      </div>

      <div class="real-estate-hero__card md:!px-60 md:!py-40 md:col-span-2">
        <p class="real-estate-hero__card--description">
          {{ t('cards.3.description') }}
        </p>

        <h3 class="real-estate-hero__card--title">
          {{ t('cards.3.title') }}
        </h3>

        <p
          class="real-estate-hero__card--description mt-lh-12">
          {{ t('cards.3.disclaimer') }}
        </p>
      </div>
    </div>

    <div class="w-full flex justify-center">
      <RoomieButton
        id="invest-real-estate-button"
        variant="primary"
        :aria-label="t('actions.investment_action.label')"
        data-lh-id="invest-real-estate-button"
        @click="emit('investment-action')">
        {{ t('actions.investment_action.title') }}
      </RoomieButton>
    </div>
  </div>
</template>

<style src="./_index.scss"></style>
<i18n src="./i18n.json"></i18n>
