<script setup lang="ts">
import RoomIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/room.svg'
import ImageShowcase from '@/components/ImageShowcase/index.vue'
import type {
  UniqueOpportunitiesProjectCardComponentModule
} from '@/components/BrandedV2/UniqueOpportunities/ProjectCard/types'

defineProps<UniqueOpportunitiesProjectCardComponentModule.Props>()
</script>

<script lang="ts">
export default {
  name: 'UniqueOpportunitiesProjectCard'
}
</script>

<template>
  <div :class="['unique-opportunities-project-card', { 'unique-opportunities-project-card__inactive': isInactive }]">
    <div class="unique-opportunities-project-card__cover">
      <ImageShowcase
        class="w-full h-full"
        :images="coverImages"
        lazy-load />
    </div>

    <div class="unique-opportunities-project-card__rooms">
      <div class="unique-opportunities-project-card__rooms-information gap-12 md:gap-10 md:min-h[calc(50% - 10px)]">
        <RoomIcon class="fill-current-color text-pine-600 w-40 h-40 md:w-68 md:h-68" />

        <h5 class="display-6 md:display-5 text-pine-600">
          {{ bedroomRange }}
        </h5>
      </div>

      <div class="unique-opportunities-project-card__rooms-picture md:min-[calc(50% - 10px)]">
        <div class="overflow-auto w-full rounded-[2.4rem]">
          <ImageShowcase
            class="w-full h-full"
            :images="galleryImages"
            lazy-load />
        </div>
      </div>
    </div>

    <div class="unique-opportunities-project-card__information">
      <div class="mb-24 md:mb-auto">
        <div class="unique-opportunities-project-card__tag ">
          <div class="relative w-[13rem] h-26">
            <span class="unique-opportunities-project-card__tag-title absolute bottom-0 flex justify-center items-center w-full h-full">
              {{ deliveredAt }}
            </span>

            <svg
              class="text-pine-600 w-[13rem] h-26"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 131 27"
              fill="none">
              <path
                d="M0.97954 4.09938C-0.256483 2.41509 0.946261 0.0406021 3.03541 0.0406023L119.902 0.0406027C120.71 0.0406028 121.469 0.422788 121.95 1.07101L129.872 11.7462C130.542 12.6487 130.542 13.8831 129.872 14.7856L121.95 25.4608C121.469 26.109 120.71 26.4912 119.902 26.4912L3.03541 26.4912C0.946259 26.4912 -0.256485 24.1167 0.979538 22.4324L6.59928 14.7746C7.2582 13.8767 7.2582 12.6551 6.59928 11.7572L0.97954 4.09938Z"
                fill="currentColor" />

              <span>hola</span>
            </svg>
          </div>
        </div>

        <h3 class="display-6 md:display-5 mb-8 md:mb-10">
          {{ name }}
        </h3>

        <p class="md:body-1-sb body-1-m">
          {{ neighborhood }}, {{ city }}
        </p>
      </div>

      <div class="flex flex-row md:flex-col gap-8">
        <p class="hl-2-sb md:display-6">
          {{ priceLabel }}
        </p>

        <h3 class="hl-2-sb md:display-6">
          {{ price }} <span class="body-1-m">{{ currency }}</span>
        </h3>
      </div>
    </div>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>
