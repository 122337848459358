<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import ProjectsByLocation from '@/components/ProjectsByLocation/index.vue'
import InvestmentFixedCta from '@/components/InvestmentFixedCta/index.vue'
import MediaReviews from '@/components/MediaReviews/index.vue'
import HomeBenefits from '@/components/HomeBenefits/index.vue'
import HomeMediaBanner from '@/components/HomeMediaBanner/index.vue'
import HomeTrajectory from '@/components/HomeTrajectory/index.vue'
import HomeFaqs from '@/components/home/HomeFaqs/index.vue'
import AppHero from '@/components/AppHero/index.vue'
import InvestorsCarousel from '@/components/BrandedV2/InvestorsCarousel/index.vue'
import HomeTestimonials from '@/components/HomeTestimonials/index.vue'
import ReferralsSection from '@/components/Referrals/Section/index.vue'
import HappyClients from '@/components/BrandedV2/HappyClients/index.vue'
import { useThemeStore } from '@/stores/useThemeStore'
import UniqueOpportunities from '@/components/BrandedV2/UniqueOpportunities/index.vue'
import PurchaseStepsIntro from '@/components/BrandedV2/PurchaseStepsIntro/index.vue'
import HomeTrajectoryV2 from '@/components/BrandedV2/HomeTrajectory/index.vue'
import RealEstateHero from '@/components/BrandedV2/RealEstateHero/index.vue'
import LaHausMedia from '@/components/BrandedV2/LaHausMedia/index.vue'
import ReferralProgramInformation from '@/components/BrandedV2/Referrals/ProgramInformation/index.vue'
import LaHausVerified from '@/components/BrandedV2/LaHausVerified/index.vue'
import HomeLocationSearchBar from '@/components/BrandedV2/HomeLocationSearchBar/index.vue'
import MeetSam from '@/components/BrandedV2/MeetSam/index.vue'
const route = useRoute()
const { locale, tm, rt, t, locales } = useI18n()

const currentLocale = locales.value.find(l => l.code === locale.value)?.iso

useSeoMeta({
  robots: 'index, follow',
  title: t('home.seo.title'),
  ogTitle: t('home.open_graph_tags.title'),
  description: t('home.seo.description'),
  ogDescription: t('home.open_graph_tags.description'),
  ogImage: t('home.open_graph_tags.image'),
  ogLocale: currentLocale || 'es-MX',
  ogSiteName: 'LaHaus'
})
const { isV2 } = useThemeStore()
const displayFixed = ref(false)

const titleList: string[] = tm('home.title')
const IADisclaimers: string[] = tm('home.ia_disclaimer')

const setObserverToAnimateSections = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('page-home__animated-section--active')
        observer.unobserve(entry.target)
      }
    })
  }, { threshold: 0.12 })

  const sections = document.querySelectorAll('.page-home__animated-section')
  sections.forEach((section) => {
    observer.observe(section)
  })
}

const setFixedCtaObserver = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      displayFixed.value = entry.isIntersecting
    })
  }, { threshold: 0 })

  const control = document.querySelector('.fixed-cta-section-control') as HTMLDivElement
  observer.observe(control)
}

const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  section?.scrollIntoView({ behavior: 'smooth' })
}

const navigateToSrp = async (customConfig?: any = {}): Promise<void> => {
  await navigateToPage({ path: '/venta/propiedades', query: route.query }, customConfig)
}

const navigateToPage = async ({ path, query = {}, customConfig = {} }: { path: string; query?: Record<string, any>, customConfig?: Record<string, any>}): Promise<void> => {
  await navigateTo({
    path,
    query: {
      ...route.query,
      ...query
    }
  }, customConfig)
}

const navigateToReferralPage = () => {
  const countryCode = locale.value
  const url = {
    co: 'https://lanzamientos.lahaus.com/asociadoslahaus/',
    mx: 'https://lanzamientos.lahaus.mx/asociadoslahaus/'
  }[countryCode]
  if (url) window.open(`${url}?utm_source=home&utm_medium=web`, '_blank')
}

onMounted(() => {
  setObserverToAnimateSections()
  !isV2 && setFixedCtaObserver()
})

</script>

<script lang="ts">
export default {
  name: 'PageHome'
}
</script>

<template>
  <div class="relative page-home">
    <div class="relative page-home__content">
      <template v-if="isV2">
        <div class="relative bg-pine-600 w-full mb-[40rem] md:mb-[26rem] min-h-[500px] md:min-h-[800px]">
          <div class="w-full flex justify-center pt-58 md:pt-74">
            <div class="flex flex-col justify-center max-w-screen-xl w-full px-24 xl:px-0">
              <h1 class="flex flex-col text-lime-600 display-4 md:display-1 mb-24 md:mb-28">
                <span
                  v-for="title in titleList"
                  :key="title">
                  {{ rt(title) }}
                </span>
              </h1>

              <p class="text-white body-1-m md:hl-3-m">
                {{ t('home.description') }}
              </p>

              <HomeLocationSearchBar
                @filters-change="navigateToSrp"
                @residential-complex-selected="navigateToPage" />
            </div>
          </div>

          <div class="absolute w-full top-[84%] md:top-[56%]">
            <InvestorsCarousel />
          </div>
        </div>

        <div class="w-full flex justify-center">
          <UniqueOpportunities class="flex justify-center max-w-screen-xl w-full" />
        </div>

        <div class="my-80 md:my-140">
          <HomeTrajectoryV2 />
        </div>

        <div class="w-full flex flex-col items-center">
          <PurchaseStepsIntro class="max-w-screen-xl px-26 xl:px-0" />

          <RealEstateHero
            class="my-80 md:my-140"
            @investment-action="navigateToSrp" />

          <ReferralProgramInformation
            class="mb-80 md:mb-140 max-w-screen-xl px-26 xl:px-0"
            @register-action="navigateToReferralPage" />

          <LaHausMedia class="mb-80 md:mb-140 flex justify-center max-w-screen-xl w-full" />
        </div>

        <HappyClients />

        <div class="my-80 md:my-140">
          <LaHausVerified />
        </div>

        <MeetSam
          @see-projects="navigateToSrp" />

        <div class="flex w-full justify-center px-24 xl:px-0 mb-lh-24">
          <div class="max-w-screen-xl w-full flex flex-col justify-center">
            <span
              v-for="item in IADisclaimers"
              :key="item"
              data-testid="ia_disclaimer"
              class="body-3-m md:body-2-m text-pine-500">
              {{ rt(item) }}
            </span>
          </div>
        </div>
      </template>

      <template v-else>
        <AppHero />

        <HomeMediaBanner
          class="page-home__animated-section"
          @go-to-section="scrollToSection" />

        <ProjectsByLocation />

        <div class="fixed-cta-section-control">
          <HomeBenefits
            class="page-home__animated-section"
            @go-to-section="scrollToSection" />

          <ReferralsSection source-page="home" />
        </div>

        <HomeTestimonials />

        <HomeTrajectory />

        <MediaReviews id="media-reviews-section" />

        <HomeFaqs />

        <Transition name="slide-fade">
          <InvestmentFixedCta v-if="displayFixed" />
        </Transition>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(90px);
}

.page-home {
  &__animated-section {
    opacity: 0;

    &--active {
      animation: fade-in-up-home 0.6s ease-out forwards;
    }
  }
}

@keyframes fade-in-up-home {
  from {
    opacity: 0;
    transform: translateY(90px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
