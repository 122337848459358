<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import RoomIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/room.svg'
import BathIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/bath.svg'

const baseCards = [
  {
    image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/LuisValePedro.png?auto=compress,enhance,format&w=372&h=462&fit=crop',
    animated_image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/LuisValePedro.gif?auto=compress,enhance,format&w=372&h=462&fit=crop',
    name: 'Luis, Vale y Pedro',
    beds: 3,
    baths: 2,
    id: 'investor-1'
  },
  {
    image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Camila&Mora.png?auto=compress,enhance,format&w=372&h=462&fit=crop',
    animated_image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Camila&Mora.gif?auto=compress,enhance,format&w=372&h=462&fit=crop',
    name: 'Camila y Mora',
    beds: 1,
    baths: 2,
    id: 'investor-2'
  },
  {
    image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Luz&Diego.png?auto=compress,enhance,format&w=372&h=462&fit=crop',
    animated_image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Luz&Diego.gif?auto=compress,enhance,format&w=372&h=462&fit=crop',
    name: 'Luz y Diego',
    beds: 2,
    baths: 2,
    id: 'investor-3'
  },
  {
    image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Edgar.png?auto=compress,enhance,format&w=372&h=462&fit=crop',
    animated_image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Edgar.gif?auto=compress,enhance,format&w=372&h=462&fit=crop',
    name: 'Edgar',
    beds: 2,
    baths: 2,
    id: 'investor-4'
  },
  {
    image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/MariaFernanda.png?auto=compress,enhance,format&w=372&h=462&fit=crop',
    animated_image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/MariaFernanda.gif?auto=compress,enhance,format&w=372&h=462&fit=crop',
    name: 'Maria Fernanda',
    beds: 2,
    baths: 2,
    id: 'investor-5'
  },
  {
    image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Patricia.png?auto=compress,enhance,format&w=372&h=462&fit=crop',
    animated_image: 'https://assests-lahaus.imgix.net/static/images/hero-carousel/Patricia.gif?auto=compress,enhance,format&w=372&h=462&fit=crop',
    name: 'Patricia',
    beds: 2,
    baths: 2,
    id: 'investor-6'
  }
]

let scrollingTimeout: ReturnType<typeof setTimeout> | null = null
let automaticScrollInterval: ReturnType<typeof setInterval> | null = null
const cards = ref([
  ...baseCards,
  ...baseCards
])

const activeCardIndex = ref<number | null>(null)
const carousel = ref<HTMLElement | null>(null)
const cardImages = ref<HTMLImageElement[] | null>(null)

const nearCardsId = computed(() => {
  if (!activeCardIndex.value) return []
  const activeCardId = cards.value[activeCardIndex.value]?.id
  if (!activeCardId) return []
  return cards.value.filter((card, index) => card.id !== activeCardId &&
    (index === activeCardIndex.value! - 1 || index === activeCardIndex.value! + 1))
    .map(card => card.id)
})
const farCardsId = computed(() => {
  if (!activeCardIndex.value) return []
  const activeCardId = cards.value[activeCardIndex.value]?.id
  if (!activeCardId) return []
  return cards.value.filter((card, index) => card.id !== activeCardId &&
    !nearCardsId.value.includes(card.id) &&
    (index >= activeCardIndex.value! + 2 || index <= activeCardIndex.value! - 2))
    .map(card => card.id)
})

const getActiveCardIndex = (): number => {
  const containerCenter = carousel.value!.offsetLeft + carousel.value!.offsetWidth / 2

  const centerCardIndex = cardImages.value?.findIndex((card, index) => {
    const cardRect = card.getBoundingClientRect()

    return containerCenter > cardRect.left && containerCenter < cardRect.right
  })

  return centerCardIndex !== -1 && centerCardIndex !== undefined ? centerCardIndex : activeCardIndex.value ?? 0
}

const onResize = () => {
  activeCardIndex.value = getActiveCardIndex()

  scrollToCard(activeCardIndex.value, 'center', false)
}

const scrollToCard = (index: number, position: 'start' |'center' |'end', animated?: boolean) => {
  activeCardIndex.value = index
  const card = cardImages.value![index]

  if (!card) return
  let scrollLeft = 0

  if (position === 'center') scrollLeft = card.offsetLeft - (carousel.value!.clientWidth / 2) + (card.clientWidth / 2)
  if (position === 'start') scrollLeft = card.offsetLeft
  if (position === 'end') scrollLeft = card.offsetLeft + card.clientWidth - carousel.value!.clientWidth

  carousel.value?.scroll({
    left: scrollLeft,
    behavior: animated ? 'smooth' : 'auto'
  })
}

const scrollToNextCard = () => {
  const nextCard = (activeCardIndex.value ?? 0) + 1
  scrollToCard(nextCard, 'center', true)
}

const onScroll = () => {
  if (scrollingTimeout) {
    clearTimeout(scrollingTimeout)
    scrollingTimeout = null
  }

  if (automaticScrollInterval) {
    clearInterval(automaticScrollInterval)
    automaticScrollInterval = null
  }

  scrollingTimeout = setTimeout(() => {
    automaticScrollInterval = setInterval(scrollToNextCard, 5000)
    scrollToCard(activeCardIndex.value!, 'center', true)

    if (scrollingTimeout) clearTimeout(scrollingTimeout)
    scrollingTimeout = null
  }, 100)

  activeCardIndex.value = getActiveCardIndex()

  if (carousel.value!.clientWidth >= carousel.value!.scrollWidth) return

  if (carousel.value!.scrollLeft >= carousel.value!.scrollWidth - carousel.value!.clientWidth - 1) {
    const cardIndex = cardImages.value!.length - baseCards.length - 1

    scrollToCard(cardIndex, 'end', false)
  } else if (carousel.value!.scrollLeft <= 0) {
    scrollToCard(baseCards.length, 'start', false)
  }
}

onMounted(() => {
  carousel.value!.scrollLeft = 1
  onResize()
  carousel.value?.addEventListener('scroll', onScroll)
  window.addEventListener('resize', onResize)
  automaticScrollInterval = setInterval(scrollToNextCard, 5000)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
  carousel.value?.removeEventListener('scroll', onScroll)
})
</script>

<script lang="ts">
export default {
  name: 'InvestorsCarousel'
}
</script>

<template>
  <div class="carousel-container">
    <div
      ref="carousel"
      class="carousel">
      <div
        v-for="(card, index) in cards"
        ref="cardImages"
        :key="card.id"
        :data-index="index"
        class="carousel__card"
        :class="{
          'card--active': activeCardIndex === index,
          'card--near': nearCardsId.includes(card.id),
          'card--far': farCardsId.includes(card.id),
        }"
        @click="scrollToCard(index, 'center', true)">
        <img
          v-show="activeCardIndex !== index"
          :src="card.image"
          loading="lazy"
          class="card__image"
          :alt="`${card.name}`">

        <img
          v-show="activeCardIndex === index"
          :src="card.animated_image"
          loading="lazy"
          class="card__image"
          :alt="`Animation of ${card.name}`">

        <div class="flex flex-col justify-end w-full h-full gap-12 pb-32 pl-32 card__content">
          <p class="hl-2-sb md:hl-1-sb text-lime-600">
            {{ card.name }}
          </p>

          <p class="flex flex-row gap-12 body-1-sb text-lime-600">
            <span class="flex flex-row items-center gap-6">
              <RoomIcon class="w-24 h-24" />
              {{ card.beds }}
            </span>

            <span class="flex flex-row items-center gap-6">
              <BathIcon class="w-24 h-24" />
              {{ card.baths }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.carousel-container {
  overflow: hidden;
  height: 450px;
  padding-top: 4px;

  @screen md {
    height: 606px;
  }
}

.carousel {
  width: 100%;
  display: flex;
  gap: 24px;
  overflow: hidden hidden;
  scroll-behavior: auto;
  align-items: flex-start;
  height: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__card {
    width: 312px;
    height: 396px;
    flex-shrink: 0;
    border-radius: 24px;
    overflow: hidden;
    transform-origin: center bottom;
    display: flex;
    align-items: flex-end;
    transform: translateY(0);
    transition: transform 1s ease, opacity 0.5s ease-in-out;
    clip-path: inset(0 0 0 0 round 24px); /* Safari Fix */

    @screen md {
      width: 372px;
      height: 462px;
    }

    .card__image{
      @apply absolute top-0 left-0 w-full h-full pointer-events-none -z-10;
    }

    .card__content {
      box-shadow:
      inset 0 -100px 30px -10px rgb(0 0 0 / 80%),
      inset 50px 0 30px -10px rgb(0 0 0 / 60%),
      inset -50px 0 30px -10px rgb(0 0 0 / 60%);
    }

    &.card--near {
      transform: translateY(50px);
    }

    &.card--far {
      transform: translateY(140px);
    }
  }
}
</style>
